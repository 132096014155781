import { useState } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import IconButton from "ui/buttons/IconButton";

function DefaultOverlay() {
  return (
    <div className="rounded-3xl">
      <div className="absolute bottom-0 right-0 mb-4 mr-4 !size-8 sm:!size-10 bg-transparent">
        <IconButton.Arrow className="bg-content1 rounded-full p-1 shadow-lg" />
      </div>
    </div>
  );
}

function BlankImageCard({ header, dataTestIdName = "image-card", className = "" }) {
  const Header = header;

  return (
    <div
      className={`flex flex-col h-full bg-content3 rounded-3xl ${className}`}
      data-testid={dataTestIdName}
    >
      <div className="invisible">
        <Header blank />
      </div>

      <div className="overflow-hidden flex relative h-full">
        <div className="w-full aspect-4/3">
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            className="w-full"
            alt="blank card"
          />
        </div>
      </div>
    </div>
  );
}
BlankImageCard.propTypes = {
  header: PropTypes.elementType.isRequired,
  dataTestIdName: PropTypes.string,
  className: PropTypes.string,
};

function ImageCardImage({ image = "", dataTestIdName = "image-card" }) {
  const [loaded, setLoaded] = useState(false);

  // If no image exists lets show a default
  return (
    <>
      {image && (
        <img
          src={image}
          onLoad={() => setLoaded(true)}
          className={`w-full h-full rounded-3xl object-cover bg-background ${!loaded ? "hidden" : ""}`}
          data-testid={`${dataTestIdName}-image`}
          alt="card"
        />
      )}

      {(!image || !loaded) && (
        <div
          className="bg-content2 flex items-center justify-center rounded-3xl h-full"
          data-testid={`${dataTestIdName}-image`}
        >
          <ImageNotSupportedOutlinedIcon className="!size-12 text-default-500" />
        </div>
      )}
    </>
  );
}
ImageCardImage.propTypes = {
  image: PropTypes.string,
  dataTestIdName: PropTypes.string,
};

function OptionalClick({ onClick = undefined, dataTestIdName = "image-card", children }) {
  if (onClick) {
    return (
      <div
        onClick={onClick}
        role="button"
        tabIndex={0}
        data-testid={`${dataTestIdName}-button`}
        className="flex flex-col h-full justify-between"
      >
        {children}
      </div>
    );
  }

  return (
    <div data-testid={`${dataTestIdName}-button`} className="flex flex-col h-full justify-between">
      {children}
    </div>
  );
}
OptionalClick.propTypes = {
  onClick: PropTypes.func,
  dataTestIdName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function ImageCard({
  image = "",
  onClick = undefined,
  blank = false,
  overlay = DefaultOverlay,
  header = () => {},
  footer = () => {},
  name = "image",
  className = "",
  overlayProps = {},
  ...bodyProps
}) {
  const ImageOverlay = overlay;
  const Header = header;
  const Footer = footer;
  const dataTestIdName = `${name}-card`;

  if (blank)
    return <BlankImageCard header={Header} className={className} dataTestIdName={dataTestIdName} />;

  return (
    <div
      className={twMerge(
        "relative rounded-3xl overflow-hidden h-full bg-content4 text-primary-foreground max-w-96 w-96",
        className,
      )}
      data-testid={dataTestIdName}
    >
      <OptionalClick onClick={onClick} dataTestIdName={dataTestIdName}>
        <Header {...bodyProps} />
        <div className="overflow-hidden flex relative">
          <div className="w-full aspect-4/3">
            <ImageCardImage image={image} dataTestIdName={dataTestIdName} />
            <ImageOverlay {...overlayProps} />
          </div>
        </div>
        <Footer {...bodyProps} />
      </OptionalClick>
    </div>
  );
}

ImageCard.propTypes = {
  image: PropTypes.string,
  onClick: PropTypes.func,
  blank: PropTypes.bool,
  overlay: PropTypes.elementType,
  header: PropTypes.elementType,
  footer: PropTypes.elementType,
  name: PropTypes.string,
  className: PropTypes.string,
  overlayProps: PropTypes.shape({}),
};

export default ImageCard;
