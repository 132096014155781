import { useState, useRef } from "react";
import { ImageCard, CropModal } from "ui";
import { writeFilesToInput, objectUrlToFile } from "utils/helpers";
import { twMerge } from "tailwind-merge";
import FormFile from "forms/file/FormFile";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";

function RemovePreviewOverlay({ onDelete }) {
  return (
    <div className="w-full">
      <div className="absolute top-2 left-2" data-testid="remove-preview">
        <CloseIcon
          className="bg-content1 rounded-full text-foreground p-1 !size-8 shadow-lg"
          role="button"
          onClick={onDelete}
        />
      </div>
    </div>
  );
}
RemovePreviewOverlay.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

function PreviewImage({ acceptedFileTypes, className, isDragReject, isInvalid }) {
  return (
    <div
      className={twMerge(
        "border border-dashed border-2 border-foreground p-8 w-full flex flex-col items-center justify-center gap-2 aspect-4/3 transition-colors duration-300 ease-in-out",
        className,
        (isDragReject || isInvalid) && "bg-danger-100 !text-danger border-danger",
      )}
      data-role="form-error-component"
      role="button"
      tabIndex={0}
    >
      <div className="inline-flex gap-1 items-center mx-auto relative ">
        <AddIcon />
        <span className="after:content-['*'] after:text-danger after:ml-0.5 rtl:after:ml-[unset] rtl:after:mr-0.5">
          Add Submission Thumbnail
        </span>
      </div>
      <p
        className={twMerge(
          "text-primary-300 block mb-0 max-w-[300px]",
          (isDragReject || isInvalid) && "!text-danger-400",
        )}
      >
        {acceptedFileTypes()}
      </p>
    </div>
  );
}
PreviewImage.propTypes = {
  acceptedFileTypes: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isDragReject: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
};

function FormFilePreviewImage({
  defaultValue = null,
  onUpdate = undefined,
  cropProps = {},
  ...fileProps
}) {
  const [previewSrc, setPreviewSrc] = useState(defaultValue);
  const [src, setSrc] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const fileRef = useRef(null);

  const handleSuccess = async (croppedSrc) => {
    setPreviewSrc(croppedSrc);
    const file = await objectUrlToFile(croppedSrc, fileRef.current.files[0].name);
    writeFilesToInput([file], fileRef.current);

    if (onUpdate) onUpdate(croppedSrc, file);
  };

  return (
    <div className="w-full sm:w-80">
      <FormFile.Dropzone
        onUpdate={(file) => {
          setSrc(URL.createObjectURL(file));
          setShowCropModal(true);
        }}
        as={PreviewImage}
        className={twMerge("rounded-3xl bg-content2 hover:bg-focus", previewSrc && "hidden")}
        maxSize={26214400}
        ref={fileRef}
        {...fileProps}
      />

      {previewSrc && (
        <ImageCard
          image={previewSrc}
          overlay={RemovePreviewOverlay}
          overlayProps={{
            onDelete: () => setPreviewSrc(null),
          }}
        />
      )}

      <CropModal
        show={showCropModal}
        onHide={() => {
          setShowCropModal(false);
          fileRef.current.value = "";
        }}
        src={src}
        onSuccess={handleSuccess}
        {...cropProps}
      />
    </div>
  );
}
FormFilePreviewImage.propTypes = {
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func,
  cropProps: PropTypes.shape({}),
};

export default FormFilePreviewImage;
