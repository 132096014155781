import { useEffect } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Button } from "ui";
import { motion } from "framer-motion";
import { Portal } from "react-portal";
import PropTypes from "prop-types";
import useSubmissionWithActions from "features/submission/hooks/useSubmissionWithActions";
import SubmissionBody from "features/submission/components/modal/SubmissionModal/SubmissionBody";
import SubmissionHeader from "features/submission/components/modal/SubmissionModal/SubmissionHeader";
import CloseIcon from "@mui/icons-material/Close";

export default function SubmissionModal({ showVoteButton = true, isOrg = false }) {
  const { submissionId } = useParams();
  const {
    submission,
    isLoading: isSubmissionLoading,
    handleSelectWinner,
    handleDeselectWinner,
  } = useSubmissionWithActions(submissionId);
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector("html")?.classList.add("overflow-hidden");

    return () => document.querySelector("html")?.classList.remove("overflow-hidden");
  }, []);

  const handleCloseSubmissionModal = () => {
    let [targetURL] = window.location.pathname.split("submission");
    if (isOrg) [targetURL] = window.location.pathname.split("org")[1].split("submission");

    return navigate(`${state?.referrer || targetURL}?${searchParams.toString()}`, {
      state: {
        scrollToTop: false,
      },
    });
  };

  return (
    <Portal isOpened>
      <div className="z-50 backdrop-opacity-disabled w-screen h-screen fixed inset-0 bg-overlay/80" />

      <motion.div
        className="flex w-screen h-[100dvh] fixed inset-0 z-50 overflow-x-auto justify-center items-start sm:items-start overflow-y-auto [--scale-enter:100%] [--scale-exit:100%] [--slide-enter:0px] [--slide-exit:80px] sm:[--scale-enter:100%] sm:[--scale-exit:103%] sm:[--slide-enter:0px] sm:[--slide-exit:0px]"
        onClick={handleCloseSubmissionModal}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <section
          className="flex flex-col relative z-50 box-border outline-none mx-1 sm:mx-6 sm:my-16 rounded-large my-16 bg-transparent border-none shadow-none max-w-6xl w-full md:w-4/5 !m-0 md:!-mt-8"
          data-testid="submission-modal"
          onClick={(e) => e.stopPropagation()}
          role="presentation"
        >
          <Button
            isIconOnly
            className="bg-content1 w-10 h-10 min-w-0 absolute md:sticky self-end right-0 top-8 mr-4 md:-mr-14"
            onClick={handleCloseSubmissionModal}
          >
            <CloseIcon className="p-1" />
          </Button>

          {!isSubmissionLoading && submission ? (
            <>
              <SubmissionHeader
                submission={submission}
                handleSelectWinner={handleSelectWinner}
                handleDeselectWinner={handleDeselectWinner}
                allowSelectWinner={isOrg}
                allowSelectSpotlight={
                  isOrg &&
                  submission.brief?.type !== "private" &&
                  submission.brief?.status !== "submitting"
                }
                showVoteButton={!isOrg && showVoteButton}
              />

              <SubmissionBody
                submission={submission}
                allowSelectWinner={isOrg}
                showVoteButton={!isOrg && showVoteButton}
                handleCloseSubmissionModal={handleCloseSubmissionModal}
              />
            </>
          ) : (
            <>
              <SubmissionHeader.Loading />
              <SubmissionBody.Loading />
            </>
          )}
        </section>
      </motion.div>
    </Portal>
  );
}

SubmissionModal.propTypes = {
  submission: PropTypes.shape({}).isRequired,
  showVoteButton: PropTypes.bool,
  isOrg: PropTypes.bool,
};
